<template>
    <div id="app">
        <MyEcharts :options="options" :width="width"></MyEcharts>
        <button @click="changeWidth">changeWidth</button>
        <button @click="changeOpt">changeOpt</button>
    </div>
</template>
  
<script>
import MyEcharts from '../../components/MyEcharts.vue'
import { options1, options2 } from './chart1'

export default {
    name: 'chart1',
    components: {
        MyEcharts
    },
    data() {
        return {
            options: options1,
            width: '600px'
        }
    },
    methods: {
        changeWidth() {
            if (this.width == '600px') {
                console.log(1)
                this.width = '800px'
            } else {
                console.log(2)
                this.width = '600px'
            }
        },
        changeOpt() {
            if (this.options == options1) {
                this.options = options2
            } else {
                this.options = options1
            }
        }
    }
}
</script>